/*
THEME: Small Apps | Bootstrap App Landing Template
VERSION: 1.0.0
AUTHOR: Themefisher

HOMEPAGE: https://mobiwak.com/products/small-apps-free-app-landing-page-template/
DEMO: https://demo.mobiwak.com/small-apps/
GITHUB: https://github.com/themefisher/Small-Apps-Bootstrap-App-Landing-Template

Get HUGO Version : https://mobiwak.com/products/small-apps-hugo-app-landing-theme/

WEBSITE: https://mobiwak.com
TWITTER: https://twitter.com/themefisher
FACEBOOK: https://www.facebook.com/themefisher
*/

@import 'variables.scss';

@import 'media-queries.scss';

@import 'typography.scss';

@import 'tabs.scss';

@import 'lists.scss';

@import 'common.scss';

@import 'button.scss';

@import 'components/navigation.scss';

@import 'components/footer.scss';

@import 'components/call-to-action.scss';

@import 'components/modal.scss';

@import 'pages/home.scss';

@import 'pages/team.scss';

@import 'pages/blog.scss';

@import 'pages/about.scss';

@import 'pages/404.scss';

@import 'pages/career.scss';

@import 'pages/faq.scss';

@import 'pages/privacy.scss';

@import 'pages/sign-in.scss';

@import 'pages/comming-soon.scss';

@import 'pages/contact.scss';

